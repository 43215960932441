import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { ROUTE_PATHS } from './routes-paths';
import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { VpRoute, vpRule } from '@zeiss/ng-vis-vp-auth/types';

export const ROUTE_CONFIG: VpRoute = {
	path: ROUTE_PATHS._,
	canActivate: [AUTH_GUARD],
	data: {
		title: 'external.tasks.title',
		rule: vpRule({ rule: RULE.system_functions, required: ['view_tasks'] }),
		routes: [
			{
				path: ROUTE_PATHS.tasks,
				data: {
					// empty string needed to not let the browser title update function
					// create duplicates (as there is no navboard on root)
					title: '',
					cache: true,
				},
			},
			{
				path: '**',
				redirectTo: ROUTE_PATHS.tasks,
			},
		],
	},
};
