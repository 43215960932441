import { Injectable } from '@angular/core';
import { Task, TaskServiceDefinition } from '@zeiss/ng-vis-common/types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TaskService implements TaskServiceDefinition {
	private openTasks: BehaviorSubject<Task[]> = new BehaviorSubject<Task[]>([]);
	private taskCounter: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	public openTasks$ = this.openTasks.asObservable();
	public taskCounter$ = this.taskCounter.asObservable();

	constructor() {}

	public setOpenTasks(tasks: Task[]): void {
		this.openTasks.next(tasks);
	}

	public setTaskCounter(counter: number): void {
		this.taskCounter.next(counter);
	}
}
