import { lastValueFrom, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Api } from 'src/environments/shared';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LOGGER_SERVICE } from '@zeiss/ng-vis-common/injection-tokens';
import {
	ApiStatusMessage,
	CorrelationHeaders,
	LoggerServiceProvider,
	LogLevel,
	Task,
	TASK_STATUS,
	TaskApiServiceProvider,
	TaskHeaders,
	TaskMethod,
	TaskQuery,
	taskStatus,
	TaskTypeMapping,
	TaskUser,
	UserAccount,
} from '@zeiss/ng-vis-common/types';
import { TaskCreation, TaskReaction } from '@zeiss/ng-vis-common/types/task/task.class';

import { ROUTE_PATHS } from '../../areas/task/routes';
import { ApiService } from '../api/api.service';
import { LogCat } from '../logger/log-cat.enum';
import { TaskService } from './task.service';

export enum TASK_API_REQUEST_TYPE {
	'GetTask' = 'GetTask',
	'PostTask' = 'PostTask',
	'PatchTask' = 'PatchTask',
	'GetTaskType' = 'GetTaskType',
}

@Injectable({
	providedIn: 'root',
})
export class TaskApiService extends ApiService implements TaskApiServiceProvider {
	/** unused */
	GetTask: (past?: boolean) => Observable<TaskUser>;
	PostTask: (task: Task) => Observable<ApiStatusMessage>;
	PatchTask: (task: Task, silent?: boolean) => Observable<ApiStatusMessage>;
	GetTaskType: () => Observable<TaskTypeMapping[]>;
	GetTaskMeta: (
		_user: UserAccount,
		taskType: number,
		taskMethod: TaskMethod,
		taskURI: string,
		taskPayload?: any,
		taskHeaders?: TaskHeaders
	) => Task;
	getTaskMeta: (
		accountId: number,
		taskType: number,
		taskMethod: TaskMethod,
		taskURI: string,
		taskPayload?: string,
		taskHeaders?: TaskHeaders,
		taskContext?: string,
		includePayloadInEmail?: boolean,
		includeContextInEmail?: boolean
	) => Task;

	constructor(
		protected http: HttpClient,
		@Inject(LOGGER_SERVICE) public loggerService: LoggerServiceProvider,
		private taskService: TaskService
	) {
		super(http, loggerService);
	}

	getTasks(query: TaskQuery) {
		const id = this.StartRequest(TASK_API_REQUEST_TYPE.GetTask);
		const headers = this.HeadersPortalBackend;

		return this.http
			.get<TaskUser>(Api.ESB.Root.Path[this._currentEnv] + Api.ESB.MDM.Path + Api.ESB.MDM.Endpoint.Task.Task, {
				params: {
					past: !query.showNewOnly,
					notificationTasks: !!query.showNotifications,
					baseTasks: !!query.showBaseTasks,
				},
				headers,
			})
			.pipe(
				tap({
					next: (taskUser: TaskUser) => {
						if (!query.showBaseTasks || !query.showNotifications) return;

						const openTasks = taskUser.tasks.filter(
							(task) => taskStatus(task) === TASK_STATUS.pending || taskStatus(task) === TASK_STATUS.unseen
						);
						this.taskService.setOpenTasks(openTasks);
						this.taskService.setTaskCounter(openTasks.length);
					},
					error: (e) => this.LogError(e, LogCat.task, undefined, headers[CorrelationHeaders.VpCorrelationId]),
				}),
				finalize(() => this.EndRequest(id))
			);
	}

	postTask(task: TaskCreation) {
		const id = this.StartRequest(TASK_API_REQUEST_TYPE.PostTask);
		const headers = this.HeadersPortalBackend;

		return this.http
			.post<ApiStatusMessage>(
				Api.ESB.Root.Path[this._currentEnv] + Api.ESB.MDM.Path + Api.ESB.MDM.Endpoint.Task.Task,
				task,
				{ headers }
			)
			.pipe(
				tap({
					next: (resp: ApiStatusMessage) => {
						const taskId = this._parseTaskIdFromApiResponseText(resp.apiStatus);

						this.loggerService.Log(resp.apiStatus, {
							level: LogLevel.success,
							category: LogCat.task,
							showToast: true,
							url: taskId ? ROUTE_PATHS._ : undefined,
							urlParams: taskId ? { taskId, reload: true } : {},
							urlActionText: 'showThisTask',
							correlationId: headers[CorrelationHeaders.VpCorrelationId],
						});
					},
					error: (err) => this.LogError(err, LogCat.task, undefined, headers[CorrelationHeaders.VpCorrelationId]),
				}),
				finalize(async () => {
					this.EndRequest(id);
					// Update tasks / requests for displaying new open tasks / requests (badges)
					await lastValueFrom(this.getTasks({ showBaseTasks: true, showNewOnly: true, showNotifications: true }));
				})
			);
	}

	patchTask(task: TaskReaction, silent = false): Observable<ApiStatusMessage> {
		const id = this.StartRequest(TASK_API_REQUEST_TYPE.PatchTask);
		const headers = this.HeadersPortalBackend;

		return this.http
			.patch<ApiStatusMessage>(
				Api.ESB.Root.Path[this._currentEnv] + Api.ESB.MDM.Path + Api.ESB.MDM.Endpoint.Task.Task,
				task,
				{ headers }
			)
			.pipe(
				tap({
					next: (resp: ApiStatusMessage) => {
						if (!silent) {
							this.loggerService.Log(resp.apiStatus, {
								category: LogCat.task,
								level: LogLevel.success,
								showToast: true,
								correlationId: headers[CorrelationHeaders.VpCorrelationId],
							});
						}
					},
					error: (err) => {
						this.LogError(err, LogCat.task, undefined, headers[CorrelationHeaders.VpCorrelationId]);
					},
				}),
				finalize(() => this.EndRequest(id))
			);
	}

	private _parseTaskIdFromApiResponseText(apiResponseText: string) {
		return +apiResponseText.split('#')[1]?.split(' ')[0];
	}
}
